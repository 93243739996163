<template>
	<div class="edit-form-container">
		<!-- :validate="" -->
		<el-form
			ref="itemForm"
			class="item-edit-form form-type-2"
			:model="formData"
			:rules="rules"
		>
			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Алиас группы атрибутов" prop="alias">
						<el-input v-model="formData.alias" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow flex wrap">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Название группы атрибутов (ru)" prop="title_ru">
						<el-input v-model="formData.title_ru" />
					</el-form-item>
				</div>

				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Назва группы атрибутов (uk)" prop="title_uk">
						<el-input v-model="formData.title_uk" />
					</el-form-item>
				</div>
			</div>

			<div class="formRow mrow">
				<div class="mcol-xs-12 mcol-sm-6">
					<el-form-item label="Категории товаров" prop="category_ids">
						<SimpleSpinner :active="categoriesLoading" />
						<el-select
							v-model="formData.category_ids"
							multiple filterable
							placeholder="выберите категорию"
							:disabed="!categoriesList.length"
							popper-class="product-category"
						>
							<el-option
								v-for="item in categoriesList"
								:key="'category_ids-' + item.id"
								:class="[
									{ 'has-children': item.count_children },
									{ 'has-parent': item.parent_id }
								]"
								:disabled="!!item.count_children"
								:label="item.title_ru"
								:value="item.id"
							/>
						</el-select>
					</el-form-item>
				</div>
			</div>
		</el-form>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { required } from '@/constants/validation';
import { itemFormMixin, itemsFetchSetMixin } from '@/mixins';
// import { removeObjProps, getItemValue /*, findItemBy*/ } from '@/helpers';

export default {
	mixins: [itemFormMixin, itemsFetchSetMixin],
	props: {
		itemData: {
			type: Object,
			default: () => ({})
		},
		fromAnotherInstance: Boolean
	},

	data() {
		return {
			itemId: null,

			formData: {
				alias: '',
				title_uk: '',
				title_ru: '',
				category_ids: []
			},

			rules: {
				// alias: required,
				title_uk: required,
				title_ru: required,
				category_ids: required
			}
		};
	},

	computed: {
		...mapState({
			categoriesList: state => state.categories.itemsList,
			categoriesLoading: state => state.categories.isLoading
		}),

		translitSettings: () => ({
			formFields: [{ prop: 'alias', sourceProp: 'title_ru', ifIsEmpty: true }]
		}),

		initialSetFetchSettings: () => [
			{
				action: 'fetch_categories',
				payload: { params: { max: -1 } },
				clean_up: 'set_categories'
			}
		]
	},

	methods: {
		...mapActions({
			fetch_categories: 'categories/fetch_categories',
			set_categories: 'categories/set_categories'
		})
	}
};
</script>
